import * as React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import AppBarDesktopLogo from "./AppBarDesktopLogo";
import AppBarMobileLogo from "./AppBarMobileLogo";
import { Link } from "react-router-dom";
import { PAGES } from "./config";

function AppBarNavContent() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <>
      <AppBarDesktopLogo />
      <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{ display: { xs: "block", md: "none" } }}
        >
          {PAGES.map((page) => (
            <Link
              to={page.to}
              key={page.label}
              style={{
                textDecoration: "none",
                color: "common.black",
              }}
            >
              <MenuItem key={page.label} onClick={handleCloseNavMenu}>
                <Typography color="common.black">{page.label}</Typography>
              </MenuItem>
            </Link>
          ))}
        </Menu>
      </Box>
      <AppBarMobileLogo />
      <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
        {PAGES.map((page) => (
          <Link
            to={page.to}
            key={page.label}
            style={{ textDecoration: "none", color: "common.black" }}
          >
            <Button
              key={page.label}
              onClick={handleCloseNavMenu}
              sx={{
                my: 2,
                display: "block",
                color: "common.black",
              }}
            >
              {page.label}
            </Button>
          </Link>
        ))}
      </Box>
    </>
  );
}
export default AppBarNavContent;
