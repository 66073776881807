import React from "react";
import { Container, Paper, Typography } from "@mui/material";
import ResponsiveAppBar from "../components/AppBar/ResponsiveAppBar";
import Footer from "../components/Footer";
import WootBlogPostList from "../components/WootBlog/WootBlogPostList";
import { WootBlogPost } from "../components/WootBlog/types";

const blogPosts: WootBlogPost[] = [
  {
    id: 1,
    title: "Placeholder 1",
    excerpt:
      "This is a placeholder excerpt for a blog post. Nothing is here yet, but this would be a summary...",
    image: "/placeholder.svg",
  },
  {
    id: 2,
    title: "Placeholder 2",
    excerpt:
      "This is a placeholder excerpt for a blog post. Nothing is here yet, but this would be a summary...",
    image: "/placeholder.svg",
  },
  {
    id: 3,
    title: "Placeholder 3",
    excerpt:
      "This is a placeholder excerpt for a blog post. Nothing is here yet, but this would be a summary...",
    image: "/placeholder.svg",
  },
  {
    id: 4,
    title: "Placeholder 4",
    excerpt:
      "This is a placeholder excerpt for a blog post. Nothing is here yet, but this would be a summary...",
    image: "/placeholder.svg",
  },
  {
    id: 5,
    title: "Placeholder 5",
    excerpt:
      "This is a placeholder excerpt for a blog post. Nothing is here yet, but this would be a summary...",
    image: "/placeholder.svg",
  },
];

export default function HomePage() {
  return (
    <Container
      style={{
        padding: "0",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ResponsiveAppBar />
      <Paper
        sx={{
          p: 2,
          flex: 1,
          display: "flex",
          flexDirection: "column",
          borderRadius: { xs: 0, lg: "20px" },
        }}
        square
      >
        <Typography variant="h4" component="h1" mb={2}>
          Recent News
        </Typography>
        <WootBlogPostList posts={blogPosts} postsPerPage={4} />
      </Paper>
      <Footer />
    </Container>
  );
}
