import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import WootLogo from "../WootLogo";

function AppBarMobileLogo() {
  return (
    <Box
      sx={{
        display: { xs: "flex", md: "none" },
        margin: "auto",
        flexGrow: 1,
      }}
    >
      <Link
        to="/home"
        style={{
          height: "46px",
        }}
      >
        <WootLogo
          style={{
            display: "block",
            height: "100%",
            width: "auto",
          }}
          wootStyles={{
            variant: "blue",
          }}
        />
      </Link>
    </Box>
  );
}
export default AppBarMobileLogo;
