import React from "react";
import {
  Box,
  Typography,
  Link,
  IconButton,
  Container,
  Grid,
} from "@mui/material";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { SvgIcon } from "@mui/material";

const MastodonIcon = () => (
  <SvgIcon
    color="secondary"
    viewBox="0 0 448 512"
    sx={{ width: "46px", height: "46px" }}
  >
    <path d="M433 179.1c0-97.2-63.7-125.7-63.7-125.7-62.5-28.7-228.6-28.4-290.5 0 0 0-63.7 28.5-63.7 125.7 0 115.7-6.6 259.4 105.6 289.1 40.5 10.7 75.3 13 103.3 11.4 50.8-2.8 79.3-18.1 79.3-18.1l-1.7-36.9s-36.3 11.4-77.1 10.1c-40.4-1.4-83-4.4-89.6-54a102.5 102.5 0 0 1 -.9-13.9c85.6 20.9 158.7 9.1 178.8 6.7 56.1-6.7 105-41.3 111.2-72.9 9.8-49.8 9-121.5 9-121.5zm-75.1 125.2h-46.6v-114.2c0-49.7-64-51.6-64 6.9v62.5h-46.3V197c0-58.5-64-56.6-64-6.9v114.2H90.2c0-122.1-5.2-147.9 18.4-175 25.9-28.9 79.8-30.8 103.8 6.1l11.6 19.5 11.6-19.5c24.1-37.1 78.1-34.8 103.8-6.1 23.7 27.3 18.4 53 18.4 175z" />
  </SvgIcon>
);

const DiscordIcon = () => (
  <SvgIcon
    color="secondary"
    viewBox="0 0 640 512"
    sx={{ width: "46px", height: "46px" }}
  >
    <path d="M524.5 69.8a1.5 1.5 0 0 0 -.8-.7A485.1 485.1 0 0 0 404.1 32a1.8 1.8 0 0 0 -1.9 .9 337.5 337.5 0 0 0 -14.9 30.6 447.8 447.8 0 0 0 -134.4 0 309.5 309.5 0 0 0 -15.1-30.6 1.9 1.9 0 0 0 -1.9-.9A483.7 483.7 0 0 0 116.1 69.1a1.7 1.7 0 0 0 -.8 .7C39.1 183.7 18.2 294.7 28.4 404.4a2 2 0 0 0 .8 1.4A487.7 487.7 0 0 0 176 479.9a1.9 1.9 0 0 0 2.1-.7A348.2 348.2 0 0 0 208.1 430.4a1.9 1.9 0 0 0 -1-2.6 321.2 321.2 0 0 1 -45.9-21.9 1.9 1.9 0 0 1 -.2-3.1c3.1-2.3 6.2-4.7 9.1-7.1a1.8 1.8 0 0 1 1.9-.3c96.2 43.9 200.4 43.9 295.5 0a1.8 1.8 0 0 1 1.9 .2c2.9 2.4 6 4.9 9.1 7.2a1.9 1.9 0 0 1 -.2 3.1 301.4 301.4 0 0 1 -45.9 21.8 1.9 1.9 0 0 0 -1 2.6 391.1 391.1 0 0 0 30 48.8 1.9 1.9 0 0 0 2.1 .7A486 486 0 0 0 610.7 405.7a1.9 1.9 0 0 0 .8-1.4C623.7 277.6 590.9 167.5 524.5 69.8zM222.5 337.6c-29 0-52.8-26.6-52.8-59.2S193.1 219.1 222.5 219.1c29.7 0 53.3 26.8 52.8 59.2C275.3 311 251.9 337.6 222.5 337.6zm195.4 0c-29 0-52.8-26.6-52.8-59.2S388.4 219.1 417.9 219.1c29.7 0 53.3 26.8 52.8 59.2C470.7 311 447.5 337.6 417.9 337.6z" />
  </SvgIcon>
);

const KickstarterIcon = () => (
  <SvgIcon
    color="secondary"
    viewBox="0 0 448 512"
    sx={{ width: "46px", height: "46px" }}
  >
    <path d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zM320.8 233l-23.3 23.1L320.8 279c24.1 23.9 24.1 63 0 86.9s-63.4 23.9-87.6 0l-8.5-8.4c-11.3 16-29.7 26.5-50.9 26.5c-34.1 0-61.9-27.5-61.9-61.4l0-133.2c0-33.8 27.7-61.4 61.9-61.4c21.1 0 39.6 10.5 50.9 26.5l8.5-8.4c24.1-23.9 63.4-23.9 87.6 0s24.1 63 0 86.9z" />
  </SvgIcon>
);

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Box
      sx={{
        p: 2,
        color: "common.white",
        display: "flex", // Add flex display
        flexDirection: "column",
        justifyContent: "center", // Center icons horizontally
        alignItems: "center", // Center icons vertically
      }}
    >
      <Box
        display="flex" // Flex container for icons
        justifyContent="center" // Center icons horizontally
        alignItems="center" // Center icons vertically
        gap={2} // Add gap for spacing between icons
      >
        <IconButton
          size="large"
          href="https://mastodon.online/@scim"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Mastodon"
          sx={{ width: "64px", height: "64px", opacity: 0.5 }}
          disabled
        >
          <MastodonIcon />
        </IconButton>
        <IconButton
          href="https://www.youtube.com"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="YouTube"
          sx={{ width: "64px", height: "64px", opacity: 0.5 }}
          disabled
        >
          <YouTubeIcon
            sx={{ width: "46px", height: "46px" }}
            color="secondary"
          />
        </IconButton>
        <IconButton
          size="large"
          href="https://discord.gg/axSRnuyQnJ"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Discord"
          sx={{ width: "64px", height: "64px" }}
        >
          <DiscordIcon />
        </IconButton>
        <IconButton
          size="large"
          href="https://www.kickstarter.com"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Kickstarter"
          sx={{ width: "64px", height: "64px", opacity: 0.5 }}
          disabled
        >
          <KickstarterIcon />
        </IconButton>
      </Box>

      <Container>
        <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
          <Link href="/privacy-policy" color="inherit" variant="body2">
            Privacy notice
          </Link>
          <Typography
            variant="body2"
            color="inherit"
            sx={{ mx: 1, opacity: 0.5 }}
          >
            |
          </Typography>
          <Link href="/terms-of-use" color="inherit" variant="body2">
            Terms of use
          </Link>
          <Typography
            variant="body2"
            color="inherit"
            sx={{ mx: 1, opacity: 0.5 }}
          >
            |
          </Typography>
          <Link href="/cookie-policy" color="inherit" variant="body2">
            Cookie policy
          </Link>
        </Grid>

        <Grid container spacing={2} justifyContent="center" textAlign="center">
          <Grid item sx={{ mt: 2 }}>
            <Typography
              variant="body2"
              sx={{ opacity: 0.5, textAlign: "center" }}
            >
              This website is operated by Loopy Studios, LLC., which is a
              company registered in Florida (registration number pending) whose
              registered office is to be determined. Use of this website is
              subject to our terms of use, privacy notice, and cookies policy.
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body2"
              sx={{ opacity: 0.5, textAlign: "center" }}
            >
              © {currentYear} Loopy Studios LLC
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
