import React from "react";
import { Container, Paper, Typography } from "@mui/material";
import ResponsiveAppBar from "../components/AppBar/ResponsiveAppBar";
import Footer from "../components/Footer";
import { useEffect } from "react";

const StripeBuyButton = () => {
  useEffect(() => {
    // Dynamically load the Stripe buy button script
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/buy-button.js";
    script.async = true;
    document.body.appendChild(script);

    // Cleanup the script when component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <stripe-buy-button
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
      buy-button-id="buy_btn_1QAMm1DISBB3cqfmP8MIRAD4"
      publishable-key="pk_live_51QAJPWDISBB3cqfmc67jiyM0RDilz6y00p24KdoJP2azZpCOGgQMNO54waxVmoOJ9SOSI7xEuZ08FOa1DG8vrR0000g3JCWkzm"
    ></stripe-buy-button>
  );
};

export default function ShopPage() {
  return (
    <Container
      style={{
        padding: "0",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ResponsiveAppBar />
      <Paper
        sx={{
          p: 4,
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          color: "common.black",
          borderRadius: { xs: 0, lg: "20px" },
        }}
        square
      >
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            margin: "auto auto",
            textAlign: "center",
          }}
        >
          <Typography variant="body2" component="p" mb={2}>
            For now, we are self-funding this adventure to make Woot Newts a fun
            game for you. If you want to help us realize our vision, consider
            donating. Soon we will offer a proper pre-game storefront where
            money can be spent on launch-day cosmetics and non-intrusive perks.
          </Typography>
          <StripeBuyButton />
        </Container>
      </Paper>
      <Footer />
    </Container>
  );
}
