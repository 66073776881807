import WootLogo from "../WootLogo";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";

function AppBarDesktopLogo() {
  return (
    <Box
      sx={{
        display: { xs: "none", md: "flex" },
        mr: 1,
        padding: 1,
      }}
    >
      <Link
        to="/home"
        style={{
          height: "46px",
        }}
      >
        <WootLogo
          style={{
            display: "block",
            height: "100%",
            width: "auto",
          }}
          wootStyles={{
            variant: "blue",
          }}
        />
      </Link>
    </Box>
  );
}
export default AppBarDesktopLogo;
