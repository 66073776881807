import React, { useId } from "react";
import theme from "../Theme";

export interface WootLogoStyles {
  variant?: "default" | "blue" | "green" | "purple";
}

interface WootLogoProps {
  className?: string;
  style?: React.CSSProperties;
  wootStyles?: WootLogoStyles;
}

interface WootLogoColorOptions {
  skin1: string;
  skin2: string;
  eyeWhite: string;
  eyeFill: string;
}

const WootLogo: React.FC<WootLogoProps> = ({
  className,
  style,
  wootStyles = { variant: "default" },
}) => {
  const getFill = (): WootLogoColorOptions => {
    switch (wootStyles.variant) {
      case "blue":
        return {
          skin1: "#1C8BE7",
          skin2: theme.palette.primary.main,
          eyeWhite: "#FFFEF1",
          eyeFill: "#403531",
        };
      case "green":
        return {
          skin1: "#57FF3C",
          skin2: "#4CBF16",
          eyeWhite: "#FFFEF1",
          eyeFill: "#403531",
        };
      case "purple":
        return {
          skin1: "#B7B6FF",
          skin2: "#6C6AE5",
          eyeWhite: "#FFFEF1",
          eyeFill: "#403531",
        };
      case "default":
      default:
        return {
          skin1: theme.palette.secondary.main,
          skin2: "#F47A2D",
          eyeWhite: "#FFFEF1",
          eyeFill: "#403531",
        };
    }
  };

  const uniqueId = useId(); // Generate a unique ID

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fill="none"
      viewBox="0 0 165 135"
      className={className}
      style={{
        maxWidth: "100%", // Ensure logo is responsive
        width: "100%",
        height: "auto", // Maintain aspect ratio
        ...style,
      }}
    >
      <defs>
        <path
          id={`path-${uniqueId}`} // Use unique ID
          fill={getFill().skin2}
          fillRule="evenodd"
          d="M165 69.5a65.5 65.5 0 0 1-89.6 61c-24.3-7.8-54-22.7-65.4-32a24 24 0 0 1 3.2-41c10.6-6.6 20-16 28.2-24.1 7-6.9 13-13 18.4-16A65.5 65.5 0 0 1 165 69.5Z"
          clipRule="evenodd"
        />
      </defs>
      <path
        fill={getFill().skin1}
        d="M115 50c0 27.6-20.1 50-45 50L25 50C25 22.4 45.1 0 70 0s45 22.4 45 50Z"
      />
      <path
        fill={getFill().eyeFill}
        stroke={getFill().eyeWhite}
        strokeWidth="6"
        d="M51.3 41.3c-2.5 12-11.7 19-19.5 17.3C24 57 18.3 47 20.8 35c2.5-12 11.7-19 19.5-17.3 7.7 1.6 13.5 11.6 11 23.6Z"
      />
      <use
        xlinkHref={`#path-${uniqueId}`}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <mask
        id={`mask-${uniqueId}`} // Use unique ID
        width="165"
        height="131"
        x="0"
        y="4"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "alpha" }}
      >
        <use
          xlinkHref={`#path-${uniqueId}`}
          fillRule="evenodd"
          clipRule="evenodd"
        />
      </mask>
      <g mask={`url(#mask-${uniqueId})`}>
        <path
          fill={getFill().skin1}
          d="M143.5 43a34.5 34.5 0 0 0 20 0h20l13 61-54.5 58L6.5 144C-8 118-31.2 63-2 75c33 13.5 38.4 8 48.5-1.5C55 65.5 64.9 56.7 77 53c13-4 23.5-24 39.5-23.5S138 41 143.5 43Z"
        />
      </g>
      <mask
        id={`mask2-${uniqueId}`} // Use unique ID
        width="165"
        height="131"
        x="0"
        y="4"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "alpha" }}
      >
        <use
          xlinkHref={`#path-${uniqueId}`}
          fillRule="evenodd"
          clipRule="evenodd"
        />
      </mask>
      <g mask={`url(#mask2-${uniqueId})`}>
        <path
          stroke={getFill().skin2}
          strokeLinecap="round"
          strokeWidth="7"
          d="M-4 84.5c11.5 8 45.4 17.5 73 21.5s51.7 3.2 56 1.5"
        />
      </g>
      <ellipse
        cx="115.5"
        cy="65.4"
        fill={getFill().eyeWhite}
        rx="22.2"
        ry="28.5"
        transform="rotate(12 115.5 65.4)"
      />
      <ellipse
        cx="115.5"
        cy="65.4"
        fill={getFill().eyeWhite}
        rx="22.2"
        ry="28.5"
        transform="rotate(12 115.5 65.4)"
      />
      <path
        fill={getFill().eyeFill}
        stroke={getFill().eyeWhite}
        strokeWidth="7"
        d="M133.7 69.2c-3 14.3-14 22.6-23.3 20.7-9.3-2-16.2-14-13.2-28.3 3-14.4 14-22.6 23.3-20.7 9.4 2 16.2 13.9 13.2 28.3Z"
      />
      <path
        stroke={getFill().skin1}
        strokeLinecap="round"
        strokeWidth="5"
        d="M13 65a25 25 0 0 1 1.5 9m18.9-4.1a25 25 0 0 0-6.3 6.6"
      />
    </svg>
  );
};

export default WootLogo;
